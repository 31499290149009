import { useGLTF } from "@react-three/drei";
import React from "react";
import { Mesh } from "three";

export const usePlasticCup = () => {
    // @ts-ignore
    return useGLTF(
        new URL("./models/RedPlasticCup.glb", import.meta.url).toString()
    ) as {
        nodes: {
            Cup: Mesh;
        };
    };
};


const PlasticCup = () => {
    const { nodes } = usePlasticCup();

    return (
        <mesh
            castShadow receiveShadow
            scale={10}
            geometry={nodes.Cup.geometry}
            material={nodes.Cup.material}
        />
    );
};
export default PlasticCup;
