import React, { Suspense, useEffect, useRef, useMemo, MouseEventHandler, MouseEvent, useState } from 'react';
import { PerspectiveCamera, Sphere, Box, Html } from "@react-three/drei";
import { PerspectiveCamera as PerspectiveCameraImpl } from 'three';
import { Canvas } from "@react-three/fiber";
import { Physics, RigidBody, RapierRigidBody, CuboidCollider, BallCollider } from "@react-three/rapier";
import { Vector3 } from 'three';
import pingSound from "./assets/ping.mp3"
import PlasticCup from './PlasticCup.tsx';



const playerOrigin = { x: 0, y: 0, z: 15 }
const ballOrigin = { x: playerOrigin.x, y: playerOrigin.y + 3, z: playerOrigin.z + 1 }
const cupOrigin = { x: playerOrigin.x, y: playerOrigin.y + 1, z: playerOrigin.z }
const tableOrigin = { x: 0, y: -0.01, z: 0 }
const cameraOrigin = { x: playerOrigin.x - 1.5, y: playerOrigin.y + 3, z: playerOrigin.z + 2.5 }

const player2Origin = { x: 0, y: 0, z: 12 }
const ball2Origin = { x: player2Origin.x, y: player2Origin.y + 3, z: player2Origin.z }
const cup2Origin = { x: player2Origin.x, y: player2Origin.y + 1, z: player2Origin.z }


const ping = new Audio(pingSound)



const App = () => {
    const rigidBody = useRef<RapierRigidBody>(null);
    const cupBody = useRef<RapierRigidBody>(null);
    const cameraRef = useRef<PerspectiveCameraImpl>(null);


    const restartBall = () => {
        rigidBody.current?.setTranslation(ballOrigin, true);
    };

    const nextPlayer = () => {
        rigidBody.current?.setTranslation(ball2Origin, true);
        cupBody.current?.setTranslation(cup2Origin, true);
    };

    const restartCamera = () => {
        cameraRef.current?.lookAt(new Vector3(playerOrigin.x, playerOrigin.y, playerOrigin.z));
    };

    const handleLeftClick: MouseEventHandler = (event: MouseEvent) => {
        console.log('Left Clicked!');
        rigidBody.current?.applyImpulse({ x: 0, y: 0.1, z: -0.01 }, true);
    };

    const handleRightClick = (e: MouseEvent) => {
        console.log('Right Clicked!');
        e.stopPropagation();
        e.preventDefault();
        restartBall()
        restartCamera()
    };

    useEffect(() => {
        restartBall()
        restartCamera()
        const handleKeyDown = (event) => {
            if (event.key === 'n' || event.key === 'N') {
                console.log('N pressed!');
                nextPlayer()
            }
            if (event.key === ' ' || event.key === 'Spacebar') {
                console.log('Space bar pressed!');
            }
            if (event.key === 'Backspace') {
                console.log('Backspace pressed!');
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const [start, setStart] = useState<Vector3>();
    const [end, setEnd] = useState<Vector3>();

    const handleMouseDown = (event) => {
        if (event.buttons === 2) {
            handleRightClick(event);
        }

        setStart(new Vector3(event.offsetX, event.offsetY, 0));
    };

    const handleMouseMove = (event) => {
        if (start) {
            setEnd(new Vector3(event.offsetX, event.offsetY, 0));
        }
    };

    const handleMouseUp = () => {
        setStart(null);
        setEnd(null);
    };

    const handleCanvasContextMenu = (e) => {
        e.preventDefault(); // Prevent default context menu
    };

    const [inCup, setInCup] = useState(false);

    return (
        <Canvas
            onContextMenu={handleCanvasContextMenu}
            onClick={handleLeftClick}
            onPointerDown={handleMouseDown}
            onPointerMove={handleMouseMove}
            onPointerUp={handleMouseUp}
        >
            <PerspectiveCamera makeDefault ref={cameraRef} position={[cameraOrigin.x, cameraOrigin.y, cameraOrigin.z]} fov={100} />
            {/* {start && end && <Line points={[start, end]} color="red" />} */}
            <Suspense>
                <Physics debug gravity={[0, -8, 0]}>
                    <RigidBody ref={cupBody} mass={10} position={[cupOrigin.x, cupOrigin.y, cupOrigin.z]} colliders="trimesh">
                        <PlasticCup />
                        <Html>PlasticCup</Html>
                        <BallCollider
                            position={[0, 0.2, 0]}
                            args={[.35]}
                            sensor
                            onIntersectionEnter={() => {
                                setInCup(true)
                            }}
                            onIntersectionExit={() => { setInCup(false) }}
                        />
                    </RigidBody>
                    <RigidBody
                        ref={rigidBody} position={[ballOrigin.x, ballOrigin.y, ballOrigin.z]} mass={0.25}
                        linearDamping={0.1} colliders={"ball"} restitution={0.7} friction={0.1}
                        onCollisionEnter={({ manifold, target, other }) => {
                            // ping.play()
                        }}

                    >
                        <Sphere scale={0.25} />
                    </RigidBody>

                    <CuboidCollider position={[tableOrigin.x, tableOrigin.y, tableOrigin.z]} args={[20, 0.5, 20]} />
                </Physics>
            </Suspense>
        </Canvas>
    );
};
export default App;
